/*------------- #TABLE OF CONTENTS --------------*/
/*------------------------------------------------------------------

1. Global / Global Styles
2. Helper / Additional classes
3. Typography / Typography Rules
4. Variables / Variables for styles
5. Blocks / Styles for Blocks
  5.1 Accordion
  5.2 Animation icons
  5.3 Buttons
  5.4 Info box
  5.5 Input
  5.6 Local SEO
  5.7 Navigation
  5.8 Offers
  5.9 Pagination
  5.10 Pagination arrows
  5.11 Popup cart
  5.12 Posts
  5.13 Services
  5.14 Socials
  5.15 Stunning headers
  5.16 Testimonials
  5.17 Range Sliders
  5.17 Preloader
6. Layouts / Styles for Layouts
  6.1 Page 404
7. Modules / Styles for Modules
  7.1 Books
  7.2 Cart
  7.3 Clients
  7.4 Comments
  7.5 Contact form
  7.6 Counters
  7.7 Features
  7.8 Footer
  7.9 Header
  7.10 Heading
  7.11 Interested products
  7.12 Order
  7.13 Our video
  7.14 Overlay menu
  7.15 Pie charts
  7.16 Pricing tables
  7.17 Product details
  7.18 Recent case
  7.19 Recent post
  7.20 Seo score
  7.21 Shop user form
  7.21 Skills
  7.21 Sliders
  7.21 Socials panel
  7.22 Subscribe form
  7.23 Team members
  7.24 Time Line
8. Widgets /  Styles for all Widgets
  8.1 Widgets
9. Fonts / Styles for fonts
10. Grid / Styles for modular grid
11. Normalize / makes browsers render all elements more consistently and in line with modern standards



-------------------------------------------------------------------*/